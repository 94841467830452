<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item prop="membershiptypeId" label="入会类型" style="width: 100%">
        <el-col :xl="10">
          <el-select clearable v-model="ruleForm.membershiptypeId" placeholder="请选择入会类型" style="width: 100%">
            <template v-for="(item,index) in membershiptypeType">
              <el-option
                  :label="item.name"
                  :value="item.index"
                  :key="'info'+ index"
              />
            </template>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-col :xl="10">
          <el-input
              placeholder="请输入姓名"
              v-model="ruleForm.name"
              clearable
              maxlength="50"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-col :xl="10">
          <el-select
              clearable
              style="width: 100%"
              v-model="ruleForm.sex"
              placeholder="请选择性别"
          >
            <el-option
                v-for="(item,index) in sexList"
                :key="'info1' + index"
                :label="item.name"
                :value="item.index"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCard" v-if="ruleForm.membershiptypeId==1">
        <el-col :xl="10">
          <el-input
              placeholder="请输入身份证号(数字0-9 X为大写)"
              v-model="ruleForm.idCard"
              clearable
              maxlength="30"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="企业名称" prop="enterpriseName" v-if="ruleForm.membershiptypeId==0">
        <el-col :xl="10">
          <el-input
              placeholder="请输入企业名称"
              v-model="ruleForm.enterpriseName"
              clearable
              maxlength="100"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="社会信用代码" prop="creditCode" v-if="ruleForm.membershiptypeId==0">
        <el-col :xl="10">
          <el-input
              placeholder="请输入社会信用代码"
              v-model="ruleForm.creditCode"
              clearable
              maxlength="100"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-col :xl="10">
          <el-input
              placeholder="请输入联系方式"
              v-model="ruleForm.phone"
              clearable
              maxlength="11"
              show-word-limit
              @blur="selectPhone"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="拟申请职务" prop="industry">
        <el-col :xl="10">
          <el-select
              clearable
              v-model="ruleForm.industry"
              style="width: 100%"
              placeholder="请选择拟申请职务"
          >
            <el-option
                v-for="(item) in industryList"
                :key="item.memberLevelId"
                :label="item.levelName"
                :value="item.memberLevelId"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="助理姓名" prop="assistantName">
        <el-col :xl="10">
          <el-input
              placeholder="请输入助理姓名"
              v-model="ruleForm.assistantName"
              clearable
              maxlength="20"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="助理电话" prop="assistantPhone">
        <el-col :xl="10">
          <el-input
              placeholder="请输入助理电话"
              v-model="ruleForm.assistantPhone"
              clearable
              maxlength="11"
              show-word-limit
          />
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {selectMemberFeeList} from '@/api/organization'
import {selectMemberPageList,} from "@/api/members";

export default {
  data() {
    return {
      membershiptypeType: [{index: 0, name: '企业'}, {index: 1, name: '个人'}],
      sexList: [{index: 1, name: '男'}, {index: 2, name: '女'}],
      industryList: [],
      disabled: false,
      ruleForm: {
        name: "",
        sex: "",
        enterpriseName: "",
        phone: "",
        industry: "",
        assistantName: "",
        assistantPhone: "",
        userId: localStorage.getItem("userId"),
        creditCode: '',
        idCard: '',
        membershiptypeId: null
      },
      fileList: [],
      rules: {
        name: [{required: true, message: "请输入姓名", trigger: "blur"}],
        enterpriseName: [{required: true, message: "请输入企业名称", trigger: "blur"}],
        sex: [{required: true, message: "请选择性别", trigger: "change"}],
        membershiptypeId: [{required: true, message: "请选择入会类型", trigger: "change"}],
        industry: [{required: true, message: "请选择拟申请职务", trigger: "change"}],
        phone: [{required: true, message: "请输入联系方式", trigger: "blur"},
          {
            type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        // creditCode: [{required: true, message: "请输入社会信用代码", trigger: "blur"}],
        // idCard: [
        //   {required: true, message: '请输入正确的身份证号', trigger: 'blur'},
        //   {
        //     validator: (rule, value, callback) => {
        //       var reg = /^(^[1-9]\d{5}[1-9]\d{3}(((0[2])([0|1|2][0-8])|(([0-1][1|4|6|9])([0|1|2][0-9]|[3][0]))|(((0[1|3|5|7|8])|(1[0|2]))(([0|1|2]\d)|3[0-1]))))((\d{4})|\d{3}[Xx])$)$/;
        //       if (reg.test(value)) {
        //         callback()
        //       } else {
        //         callback(new Error('请输入正确的身份证号'))
        //       }
        //     }
        //   }
        // ]
      },
    };
  },
  mounted() {
    this.queryPageMoney()
  },
  methods: {
    async queryPageMoney() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await selectMemberFeeList(data);
        this.industryList = result.data;
      } catch (error) {

      }
    },
    submitForm(payload) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          this.$emit("submitForm", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async selectPhone(){
      let ajax = {
        tel:this.ruleForm.phone,
        associationId: localStorage.getItem("associationId"),
      }
      try {
        const result = await selectMemberPageList(ajax);
        console.log(result)
        if (result.code == 200) {
          this.$message({
            type: 'error',
            message: '该手机号已注册会员，请更换手机号！'
          });
          this.ruleForm.phone = ''
        }
      } catch (error) {

      }
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
